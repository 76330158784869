import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import naive from 'naive-ui'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import * as msal from "@azure/msal-browser";


const app = createApp(App)
app.use(VueAxios, axios)
app.use(naive)
app.use(msal)
app.use(MonthPicker)
app.use(MonthPickerInput)

app.mount('#app')