<template>

  <n-grid x-gap="12" y-gap="10" :cols="3" v-if="accountinfo_global.username">
    <n-grid-item :offset="1" >


      <n-space justify="space-between">
     <n-image
    width="100"
    src="https://assets.dtc.beer/assets/DTC_LOGO_light.png"
  />
     <h1>Pipeline Mexico Sell Out Offline Master Run </h1></n-space>


    </n-grid-item>
    <n-grid-item :offset="1" y-gap="30">
        <n-text type="info"  v-if="!success.length">Hello {{ accountinfo_global.name }}, Please fill the form in order to run a pipeline. {{ posts.business_name }}</n-text>
            <n-input v-model:value="accountinfo_global.username" :default-value="accountinfo_global.name" type="text" />

    </n-grid-item>
      <n-grid-item :offset="1" y-gap="50">
        <n-space justify="start"><n-text type="warning" >Run pipeline with default parameters or choose custom dates: </n-text></n-space>
              <n-grid x-gap="12" :cols="2" y-gap="12">
                <n-button type="primary" size="large" @click="defaultDates">Run with default parameters</n-button>
                <n-button type="primary" size="large" @click="customDates">Choose custom dates</n-button>
              </n-grid>
        </n-grid-item>
    <n-grid-item span="4">

        <form @submit="postData" method="post">

            <n-space vertical :size="16">
              <n-alert  v-if="success.length"  title="Success Text" type="success">
                Thank you for the form submission ! You will receive an email notification once the request has been handled, this usually takes from 5 to 30 minutes.
              </n-alert>
              <n-alert  v-if="errors.length"  title="Error Text" type="error"> Please check if the information in the form is correct</n-alert>
            </n-space>
          <n-space vertical size="medium" inline="true">

            </n-space>

            <n-space vertical v-if="!success.length"  size="medium" inline="true">
              <n-grid x-gap="12" :cols="2" v-if="dateOption">

              	<n-gi><p>Previous date{{posts.prev_month ? ": " + posts.prev_month + ", " + posts.prev_year : ''}}</p> <month-picker no-default=true @change="showPrevDate"></month-picker></n-gi>
                <n-gi><p>Current date{{posts.month ? ": "+ posts.month + ", " +  posts.year : ''}}</p><month-picker @change="showDate" no-default=true></month-picker></n-gi>
              </n-grid>

<!--              <n-date-picker v-model:value="range" type="daterange" clearable />-->

<!--              <n-grid x-gap="12" :cols="2">-->
<!--                <n-gi><n-select v-model:value="posts.market_country" :options="market_options" placeholder="Choose the market" /></n-gi>-->
<!--                <n-gi><n-select v-model:value="posts.business_name" :options="business_name_options" placeholder="Choose the business" /></n-gi>-->
<!--              </n-grid>-->
<!--              <n-select v-model:value="posts.business_categories"  multiple :options="business_category_options_filtered" placeholder="Choose the categories" />-->

              <n-button type="primary" attr-type="submit" v-if="posts.month && posts.prev_month" size="large">Submit your request</n-button>
            </n-space>
        </form>
    </n-grid-item>
  </n-grid>


</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { MonthPicker } from 'vue-month-picker'
const axios = require('axios');
const msal = require("@azure/msal-browser");


const msalConfig = {
    auth: {
        clientId: 'b11ba6fe-f8db-408c-b1d9-b418e2d2dbc7',
        authority: `https://login.microsoftonline.com/cef04b19-7776-4a94-b89b-375c77a8f936`,
    }
};

var loginRequest = {
    scopes: ["user.read", "mail.send"] // optional Array<string>
};

const months = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
}

/* add https://vee-validate.logaretm.com/v4/ */

export default defineComponent({
  name: 'RequestForm',
  setup: () => {


  /* move this to root, and then include to src/repository/user/index.js, then const login= */
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    console.log("accounts" + accounts)
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const EventType = ''

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    }, error=>{
      console.log('error', error);
    });


    const loginUser = async () => {
            // handle auth redired/do all initial setup for msal
          let accountinfo = await msalInstance.handleRedirectPromise().then(authResult=>{
              // Check if user signed in 
              console.log(authResult);
              const account = msalInstance.getActiveAccount();

              if(!account){
                // redirect anonymous user to login page 
                msalInstance.loginRedirect(loginRequest);
              }else{
                return account
              }
            }).catch(err=>{
              // TODO: Handle errors
              console.log(err);
            });
            return accountinfo
    }

    const accountinfo_global = ref([]);

    const getRemoteData = () => {
      loginUser().then((accountinfo)=>{
        accountinfo_global.value = accountinfo

      })
    }

    onMounted(() => {
      getRemoteData()
    })

    return {
      accountinfo_global
    }

  },  
  data(){

    console.log('8-8-',this.accountinfo_global);

    return{
        errors: [],
        success: [],
        posts: {
            year:null,
            month:null,
            prev_year:null,
            prev_month:null,
            email_recipients:this.accountinfo_global.email
        },
        dateOption: false,
        range: ref([1553135260000, Date.now()])
    }
  },
  watch:{
    "posts.business_name":{
      handler(){
        this.businessNameChange();
      }
    }
  },
  components: {
		MonthPicker
	},
  methods:{
    customDates () {
      this.dateOption = true
    },

    showDate (date) {
      this.posts.month = date.month
      this.posts.year = date.year
		},
    showPrevDate (prevDate) {
      this.posts.prev_month = prevDate.month
      this.posts.prev_year = prevDate.year
		},
     businessNameChange: function () {
       this.business_category_options_filtered = this.business_category_options.filter(business_category_option => business_category_option.label.includes(this.posts.business_name.toLowerCase()));
     },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    defaultDates(e) {
      console.warn('default params run')
      this.posts.email_recipients = this.accountinfo_global.username
      this.axios.post('https://prod-240.westeurope.logic.azure.com:443/workflows/8ca3dc7d3fb644e4901c8e53225b30d5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kS9ukcFW9mmv5fgWUWGJg1Mc-kmNSN6E5ze5ExKvQCs',this.posts)
        .then((result)=>{
            console.warn(result)
            if(result.data.success == "True"){
              this.success.push('success');
            }else{
              this.errors['backend_error']=1;
            }
        })
        e.preventDefault();

    },
    postData(e){
        console.warn('run pipeline with params')
        this.posts.email_recipients = this.accountinfo_global.username
        let postsDatesMapped = this.posts
        postsDatesMapped.prev_month = months[this.posts.prev_month]
        postsDatesMapped.month = months[this.posts.month]
        console.warn(this.posts)
        this.axios.post('https://prod-240.westeurope.logic.azure.com:443/workflows/8ca3dc7d3fb644e4901c8e53225b30d5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kS9ukcFW9mmv5fgWUWGJg1Mc-kmNSN6E5ze5ExKvQCs', postsDatesMapped)
        .then((result)=>{
            console.warn(result)
            if(result.data.success == "True"){
              this.success.push('success');
            }else{
              this.errors['backend_error']=1;
            }
        })
        e.preventDefault();
    }
  }
})
</script>